<template>
  <v-radio-group
    v-model="selectedPaymentMethod"
    column
  >
    <v-radio
      v-for="(paymentMethod, index) in paymentMethods"
      :key="index"
      color="black"
      class="payment-method"
      :value="paymentMethod.value"
    >
      <template v-slot:label>
        <div class="payment">
          <span>
            <img
              class="payment-icon"
              :src="paymentMethod.icon"
            >
          </span>
          <span class="payment-name">
            {{ paymentMethod.name }}
          </span>
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>
<script>
// import Ticket from '@/assets/payment_methods/ticket.svg';
import Pix from '@/assets/payment_methods/pix.svg';
// import AddCreditCard from '@/assets/payment_methods/add_credit_card.svg';
// import CreditCard from '@/assets/payment_methods/credit_card.svg';

export default {
  name: 'PaymentMethod',
  data() {
    return {
      paymentMethods: [
        // {
        //   name: this.$t('components.payment_method.add_new_card'),
        //   value: 'add-new-card',
        //   icon: AddCreditCard,
        // },
        // {
        //   name: this.$t('components.payment_method.credit_cart'),
        //   value: 'credit-card',
        //   icon: CreditCard,
        // },
        {
          name: this.$t('components.payment_method.pix'),
          value: 'pix',
          icon: Pix,
        },
        // {
        //   name: this.$t('components.payment_method.ticket'),
        //   value: 'bank_slip',
        //   icon: Ticket,
        // },
      ],
      selectedPaymentMethod: null,
    };
  },

  watch: {
    selectedPaymentMethod(newValue) {
      this.$emit('selectPayment', newValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-method {
  background-color: $color_light;
  width: 100%;
  margin: 10px 0;
  padding: 10px;

  .payment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .payment-icon {
      width: 40px;
      height: 40px;
      margin-left: 18px;
      margin-right: 26px;
    }

    .payment-name {
      color: $color_dark;
      font-weight: bold;
    }
  }
}
</style>
