<template>
  <div class="card-cart-item-checkout">
    <div>
      <span class="text-material-type">
        {{ $t('components.card_cart_item_checkout.type') }}
      </span>
      <span class="font-weight-bold">{{ item.credit_offered.material_type }}</span>
    </div>
    <div>
      <span class="text-quantity">
        {{ $t('components.card_cart_item_checkout.credit_quantity.text') }}
      </span>
      <span class="font-weight-bold">
        {{ $t('components.card_cart_item_checkout.credit_quantity.value', {
          value: item.credit_offered.credits_quantity
        }) }}
      </span>
    </div>
    <div>
      <span class="text-unit-value">
        {{ $t('components.card_cart_item_checkout.unit_value') }}
      </span>
      <span class="font-weight-bold">{{ formatMoney(item.credit_offered.unit_value_with_aditional) }}</span>
    </div>
    <div>
      <span class="text-unit-value">
        {{ $t('components.card_cart_item_checkout.central_custody_fee_value') }}
      </span>
      <span class="font-weight-bold">{{ formatMoney(item.credit_offered.central_custody_fee_value) }}</span>
    </div>
  </div>
</template>

<script>
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'CardCartItemCheckout',
  mixins: [formatMoney],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-cart-item-checkout {
    margin: 20px 0px;

    .text-material-type,
    .text-mass-quantity,
    .text-quantity,
    .text-unit-value {
      color: $color_gray;
      margin-right: 6px;
    }
  }
</style>
