<template>
  <div class="cart-checkout">
    <div class="title-cart-checkout">
      <back-button
        class="back-button"
        :text="$t('views.cart_checkout.back')"
        @click="goToHome()"
      />
      <span class="page">{{ $t('views.cart_checkout.title') }}</span>
    </div>
    <card-checkout
      :items="cartItems"
      :total-value="totalValue"
      @selectPayment="selectPayment"
      @goToPayment="goToPayment"
    />

    <confirm-order-dialog />

    <mandatory-offers-dialog />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CardCheckout from '@/components/cards/checkout/CardCheckout.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import ConfirmOrderDialog from '@/components/dialogs/ConfirmOrderDialog.vue';
import MandatoryOffersDialog from '@/components/dialogs/MandatoryOffersDialog.vue';

export default {
  name: 'CartCheckout',
  components: {
    BackButton, CardCheckout, ConfirmOrderDialog, MandatoryOffersDialog,
  },

  data() {
    return {
      paymentMethod: null,
      dialodPayment: false,
    };
  },

  computed: {
    ...mapGetters({
      cartItems: 'getCartItems',
      business: 'getBusiness',
      order: 'getOrder',
    }),

    cartItemsIds() {
      return this.cartItems.map((item) => item.id);
    },

    totalValue() {
      const quantities = this.cartItems.map((item) => Number(item.total_value));

      if (quantities.length <= 0) return 0;

      return quantities.reduce((previousValue, currentValue) => previousValue + currentValue);
    },
  },

  watch: {
    order(value) {
      if (value) this.dialodPayment = true;
    },

    dialodPayment(value) {
      if (!value) this.goToHome();
    },
  },

  mounted() {
    if (this.cartItems.length <= 0) this.$router.push({ name: 'cart' });
  },

  methods: {
    ...mapActions(['fetchCartItems', 'createOrder', 'addErrorMessage']),

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    selectPayment(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },

    goToPayment() {
      if (!this.paymentMethod) {
        this.addErrorMessage(this.$t('components.payment_method.error_not_selected'));
      } else {
        this.createOrder({
          business_id: this.business.id,
          payment_method: this.paymentMethod,
          cart_items_ids: this.cartItemsIds,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cart-checkout {
  background: $dashboard_background;
  min-height: 100vh;
  padding: 2em 2em 2em 16em;
  display: flex;
  flex-direction: column;

  .title-cart-checkout {
    display: flex;
    align-items: flex-end;
    margin: 30px 0 40px 0;
    position: relative;

    .page {
      font-size: 1.5em;
      font-weight: bold;
      margin: auto;
    }

    .back-button {
      position: absolute;
    }
  }
}
</style>
