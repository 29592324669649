<template>
  <div class="card-checkout">
    <div class="content-payment-methods">
      <div class="payment-methods">
        <div class="title-payment">
          {{ $t('components.card_checkout.payment_method.title') }}
        </div>
        <payment-method @selectPayment="selectPayment" />
      </div>

      <rectoplus-button
        class="button-next"
        elevation="0"
        :text="$t('components.card_checkout.button')"
        @click="goToPayment()"
      />
    </div>

    <div class="resume-buy">
      <div>
        <div class="title-resume">
          {{ $t('components.card_checkout.resume_buy.title') }}
        </div>
        <div class="overflow-items">
          <card-cart-item-checkout
            v-for="(item, index) in items"
            :key="index"
            :item="item"
          />
        </div>
      </div>
      <div>
        <div class="will-pay">
          {{ $t('components.card_checkout.resume_buy.will_pay') }}
        </div>
        <div class="total">
          {{ formatMoney(totalValue) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethod from '@/components/cards/checkout/PaymentMethod.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import CardCartItemCheckout from '@/components/cards/checkout/CardCartItemCheckout.vue';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'CardCheckout',
  components: { PaymentMethod, RectoplusButton, CardCartItemCheckout },
  mixins: [formatMoney],
  props: {
    items: {
      type: Array,
      required: true,
    },

    totalValue: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    selectPayment(paymentMethod) {
      this.$emit('selectPayment', paymentMethod);
    },

    goToPayment() {
      this.$emit('goToPayment');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-checkout {
  width: 100%;
  height: 100%;
  max-height: 530px;
  margin-bottom: 20px;
  display: flex;

  .content-payment-methods {
    width: 70%;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .payment-methods {
      background-color: $color_white;
      padding: 20px;
      width: 100%;
      height: 100%;
      border-radius: 8px;

      .title-payment {
        font-size: 1.2em;
        font-weight: bold;
      }
    }

    .button-next {
      margin-top: 20px;
      width: 100%;
      max-width: 300px;
      height: 56px;
    }
  }

  .resume-buy {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $background_resume_buy;
    padding: 14px;
    padding-right: 4px;
    border-radius: 8px;
    font-size: 0.9em;

    .title-resume {
      margin-top: 20px;
    }

    .title-resume, .total {
      font-size: 1.5em;
      font-weight: bold;
    }

    .overflow-items {
      max-height: 368px;
      overflow-y: auto;
    }

    .will-pay {
      color: $color_gray;
    }
  }
}
</style>
