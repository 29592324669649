<template>
  <v-col
    v-show="dialog"
    cols="auto"
  >
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-top-transition"
      max-width="600"
    >
      <div class="card-dialog text-justify">
        <span
          class="btn-close"
          @click="closeDialog()"
        >
          {{ $t('components.dialogs.confirm_order.close') }}
        </span>
        <p class="title-text">
          {{ $t('components.dialogs.confirm_order.title') }}
        </p>

        <div class="confirm-text">
          <!-- TODO: texto provisório -->

          <!-- <p>
            {{ $t('components.dialogs.confirm_order.text_validate') }}
          </p>

          <p class="text-center">
            <a
              :href="paymentLink"
              target="_blank"
            >
              {{ $t('components.dialogs.confirm_order.link') }}
            </a>
          </p> -->

          <p>
            {{ $t('components.dialogs.confirm_order.text_get_bank_slip') }}
          </p>
        </div>
      </div>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ConfirmOrderDialog',

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters(['getConfirmOrderDialog', 'getOrder']),

    // paymentLink() {
    //   return this.getOrder.payment.link_to_payment;
    // },
  },

  watch: {
    getConfirmOrderDialog(value) {
      this.dialog = value;
    },
  },

  methods: {
    ...mapActions(['confirmOrderDialog']),

    closeDialog() {
      this.confirmOrderDialog(!this.dialog);

      this.$router.push({
        name: 'home',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-dialog {
  position: relative;
  overflow: hidden;

  .btn-close {
    position: absolute;
    top: 16px;
    right: 20px;
    font-size: 2em;
    color: $color_primary;
    font-weight: bold;
    cursor: pointer;
    transition-duration: 300ms;

    &:hover {
      font-size: 2.5em;
    }
  }

  padding: 40px;
  border-radius: 10px;
  background: $color_light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title-text {
    color: $color_dark;
    font-size: 1.875em;
    margin-bottom: 30px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  .confirm-text {
    text-align: justify;
    color: $color_secondary;
    font-size: 1em;
  }
}

.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-size: 1.5em;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>
