<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition"
    max-width="600"
  >
    <div class="card-dialog">
      <button
        class="button-close"
        @click="closeDialog()"
      >
        <v-icon color="primary">
          mdi-close
        </v-icon>
      </button>

      <h1 class="title">
        {{ $t('components.mandatory_offers_dialog.title') }}
      </h1>

      <p>
        {{ $t('components.mandatory_offers_dialog.subtitle') }}
      </p>

      <ul class="list">
        <li
          v-for="(id, index) in getMandatoryOffers"
          :key="index"
          class="item"
        >
          <router-link
            :to="{ name: 'offered', params: { id } }"
            class="button"
          >
            {{ $t('components.mandatory_offers_dialog.button') }}
          </router-link>
        </li>
      </ul>

      <button
        class="link"
        @click="removeCartItems()"
      >
        {{ $t('components.mandatory_offers_dialog.link_remove_offers') }}
      </button>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MandatoryOffersDialog',

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters([
      'getMandatoryOffersDialog',
      'getMandatoryOffers',
    ]),
  },

  watch: {
    getMandatoryOffersDialog(value) {
      this.dialog = value;
    },
  },

  beforeDestroy() {
    this.closeDialog();
  },

  methods: {
    ...mapActions(['closeMandatoryOffersDialog', 'removeCartItemsWithRelation']),

    closeDialog() {
      this.closeMandatoryOffersDialog();
    },

    async removeCartItems() {
      await this.removeCartItemsWithRelation();

      this.closeDialog();

      this.$router.push({ name: 'cart' });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px;
  border-radius: 10px;
  background: $dialog_bank_account_background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .button-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .link {
    background-color: transparent;
    color: $color_primary;
    text-decoration: underline;
  }

  .list {
    width: 100%;
    margin-top: 30px;

    .item {
      list-style: none;
      width: 100%;
      margin-bottom: 20px;

      .button {
        width: 100%;
        background-color: transparent;
        border: 2px solid $color_primary;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        text-decoration: none;
        color: $color_primary;
        font-weight: bold;
        display: block;
      }
    }
  }
}
</style>
